import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { NTUCMemberList } from "../../services/ntuc";

const PrintCards = ({ ntucmembers }: { ntucmembers: NTUCMemberList[] }) => {
    const printRef = useRef<HTMLDivElement>(null);
    const [topSpace, setTopSpace] = React.useState(0);
    const [generatingStatus, setGeneratingStatus] = React.useState(false);
    const exportToPDF = async () => {
        if (printRef.current) {
            setGeneratingStatus(true);
            const element = printRef.current;

            // Render the section to a canvas with higher quality settings
            const canvas = await html2canvas(element, {
                scale: 3,
                useCORS: true, // Enable cross-origin for images
                logging: false,
                allowTaint: true,
                imageTimeout: 0
            });
            const data = canvas.toDataURL("image/png");

            // Create a PDF with better quality settings
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a4",
                compress: false
            });

            // Calculate dimensions
            const pageHeight = 297; // A4 height in mm
            const margin = -3; // Add a margin of -3mm
            const imgWidth = 210 - margin * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = margin;

            while (heightLeft > 0) {
                pdf.addImage(data, "PNG", margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight - margin * 2;
                if (heightLeft > 0) {
                    pdf.addPage();
                    position = margin; // Reset position for new page
                }
            }

            // Save the PDF
            pdf.save(`${Date.now()}.pdf`);

            setGeneratingStatus(false);
        }
    };

    return (
        <div>
            <div className="flex justify-end">
                {generatingStatus && <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-md"
                >
                    Generating...
                </button>}
                {!generatingStatus && <button
                    onClick={exportToPDF}
                    className="bg-blue-500 text-white py-2 px-4 rounded-md"
                    onMouseEnter={() => setTopSpace(16)}
                    onMouseLeave={() => setTopSpace(0)}
                >
                    Export Card
                </button>}
            </div>
            <div className="left-[0] right-0 top-[210px] bg-white w-[1000px] px-3 fixed opacity-0">
                {/* Cards Section - Changed from grid-cols-3 to grid-cols-2 */}
                <div ref={printRef} className="grid grid-cols-2 gap-4 pt-5">
                    {ntucmembers.map((ele, index) => (
                        <div key={ele._id} className="relative w-[465px] h-[640px]">
                            <img
                                src="./card.jpeg"
                                alt={ele.name}
                                className="h-[640px] object-contain m-auto"
                            />
                            <div
                                className="absolute h-[166px] left-[165px] overflow-hidden rounded-[10px] top-[260px] w-[134px]"
                                style={{ backgroundImage: `url(${ele.media?.mediaUrl || '/default-image.jpg'})`, backgroundSize: "cover", backgroundPosition: 'center' }}
                            >
                            </div>
                          
                            <span className={`absolute left-[156px] text-[16px] text-[#000] font-medium`} style={{ top: `${523 - topSpace}px` }}>
                                {ele.structure}
                            </span>
                            <span className={`absolute left-[156px] text-[16px] text-[#000] font-medium`} style={{ top: `${433 - topSpace}px` }}>
                                {ele.name}
                            </span>
                            <span className={`absolute left-[156px] text-[16px] text-[#000] font-medium`} style={{ top: `${463 - topSpace}px` }}>
                                {ele.district}
                            </span>
                            <span className={`absolute left-[156px] text-[16px] text-[#000] font-medium`} style={{ top: `${493 - topSpace}px` }}>
                                {ele.agency}
                            </span>
                            <span className={`absolute left-[378px] text-[13px] text-[#000] font-medium`} style={{ top: `${15 - 8}px` }}>
                                {ele.roll}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PrintCards;