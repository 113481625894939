import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
// @ts-ignore
import preeti from "preeti";
import { addNTUCMember, District, getNTUCMembers, getNepalAddress, NTUCAddressResult, NTUCMemberList, Pagination, deleteNTUCMember, getNTUCMember, Province } from "../../services/ntuc";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

interface FormInputs {
  roll: string;
  name: string;
  province: string;
  district: string;
  image: File[];
  agency: string;
  structure: string;
}

const unions = [
  {
    english: "Nepal Teachers Association",
    nepali: "नेपाल शिक्षक संघ"
  },
  {
    english: "Nepal Factory Labour Union",
    nepali: "नेपाल कारखाना श्रमिक संघ"
  },
  {
    english: "Construction and Allied Workers Union Nepal",
    nepali: "नेपाल निर्माण तथा सम्बद्ध श्रमिक संघ"
  },
  {
    english: "Agriculture workers union of Nepal",
    nepali: "नेपाल कृषि श्रमिक संघ"
  },
  {
    english: "Nepal Tourism, Hotel, Casino and Restaurant Workers Union",
    nepali: "नेपाल पर्यटन तथा होटल सम्बद्ध श्रमिक संघ"
  },
  {
    english: "Union of Public Services in Nepal",
    nepali: "नेपाल सार्वजनिक सेवा कर्मचारी संघ"
  },
  {
    english: "Nepal Embroidery, Handicraft and Sewing Knitting Workers Union",
    nepali: "नेपाल इम्ब्रोइडरी, हेण्डीक्राफ्ट तथा सिलाई बुनाई श्रमिक संघ"
  },
  {
    english: "Nepal Tea Garden labour union",
    nepali: "नेपाल चियावगान श्रमिक संघ"
  },
  {
    english: "Financial Institute Employees Union of Nepal",
    nepali: "नेपाल वित्तिय संस्था कर्मचारी संघ"
  },
  {
    english: "Barber Union of Nepal",
    nepali: "नेपाल नाई संघ"
  },
  {
    english: "Nepal Customs & Airport Allied workers union",
    nepali: "नेपाल भन्सार तथा विमानस्थल सम्बद्ध श्रमिक संघ"
  },
  {
    english: "Health Professional Union of Nepal",
    nepali: "नेपाल स्वास्थ्यकर्मी संघ"
  },
  {
    english: "Nepal Carpet Workers Union",
    nepali: "नेपाल गलैंचा श्रमिक संघ"
  },
  {
    english: "Nepal Transport Workers Union",
    nepali: "नेपाल यातायात मजदुर संघ"
  },
  {
    english: "Nepal Garment Workers Union",
    nepali: "नेपाल गार्मेन्ट मजदुर संघ"
  },
  {
    english: "Nepal Beautician Union",
    nepali: "नेपाल व्युटिसियन संघ"
  },
  {
    english: "Nepal Printing Press & Allied Workers Union",
    nepali: "नेपाल छापाखाना तथा सम्बद्ध श्रमिक संघ"
  },
  {
    english: "Nepal Commercial & Allied Worker's Union",
    nepali: "नेपाल व्यापार तथा सम्बद्ध श्रमिक संघ"
  },
  {
    english: "Nepal Press Union",
    nepali: "नेपाल पे्रस यूनियन"
  },
  {
    english: "Nepal Home Workers Union",
    nepali: "नेपाल गृह श्रमिक संघ"
  },
  {
    english: "Nepal Rickshaw and Puller workers union",
    nepali: "नेपाल अटो रिक्सा, ई–रिक्सा तथा ठेला सम्बद्ध श्रमिक संघ"
  },
  {
    english: "Nepal Film Workers Union",
    nepali: "नेपाल चलचित्र श्रमिक संघ"
  },
  {
    english: "Nepal Porter Union",
    nepali: "नेपाल भारवाहक श्रमिक संघ"
  },
  {
    english: "Nepal Cultural and Art Workers Union",
    nepali: "नेपाल सांस्कृतिक तथा कलाकर्मी संघ"
  },
  {
    english: "Nepal National Security Guard Workers Union",
    nepali: "नेपाल राष्ट्रिय सुरक्षा गार्ड श्रमिक संघ"
  },
  {
    english: "Nepal National Security Guard Workers Union",
    nepali: "स्थानीय तह कर्मचारी युनियन नेपाल"
  },
  {
    english: "Nepal National Security Guard Workers Union",
    nepali: "नेपाल पशु स्वास्थ्य सेवा प्राविधिक संघ"
  },
  {
    english: "Nepal National Security Guard Workers Union",
    nepali: "३ प्रतिशत"
  }
];

const AddPrintCard: React.FC = () => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [roll, setRoll] = useState<string>(""); // Nepali converted roll
  const [name, setName] = useState<string>(""); // Nepali converted name
  const [agency, setAgency] = useState<string>(""); // Nepali converted agency
  const [province, setProvince] = useState<string>(""); // Nepali converted agency
  const [provinces, setProvinces] = useState<NTUCAddressResult>(); // Nepali converted province
  const [districts, setDistricts] = useState<District[]>([]);
  const [union, setUnion] = useState<string>("");
  const [district, setDistrict] = useState<string>(""); // Nepali converted district
  const [submitLoading, setSubmitLoading] = useState(false);
  const [members, setMembers] = useState<NTUCMemberList[]>();
  const [structure, setStructure] = useState<string>("");
  const [memberPagination, setMemberPagination] = useState<Pagination>();
  const { id } = useParams()
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    resetField,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("roll", data.roll);
    formData.append("structure", data.structure);
    formData.append("name", data.name);
    formData.append("province", data.province);
    formData.append("district", data.district);
    formData.append("agency", data.agency);
    formData.append("fileLocation", 'ntucmember');
    formData.append("compressionType", 'normal');
    formData.append("targetSize", '90');
    formData.append("imageFormat", 'jpeg');
    if (id) {
      formData.append("_id", id)
    }

    // Handle the image file
    if (data.image && data.image.length > 0) {
      formData.append("media", data.image[0]);
    }
    const response = await addNTUCMember(formData);
    setSubmitLoading(false);
    if (response.status === 201 || response.status === 200) {
      toast.success(response.data.message);
      setMemberPagination(response.data.pagination);
      setMembers(response.data.results);
      if (!id) {
        reset();
        resetField("image");
        setImagePreview(null);
        setDistricts([]);
        setDistrict("");
        setProvince("");
        setRoll("");
        setName("");
        setAgency("");
        setStructure("");
      }
      navigate(-1)
    } else {
      toast.error(response.data.message);
    }
  };


  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault(); // Prevent default behavior to allow dropping
    e.stopPropagation();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleImageUpload(file); // Update the preview
      setValue("image", [file]); // Update the form value for `image`
      clearErrors("image"); // Clear the "required" error
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      handleImageUpload(file); // Update the preview
      setValue("image", [file]); // Update the form value for `image`
      clearErrors("image"); // Clear the "required" error
    }
  };

  const handleNepaliChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setter(preeti(e.target.value)); // Convert to Nepali using preeti
      };



  useEffect(() => {
    (async () => {
      const resultProvince = await getNepalAddress();
      setProvinces(resultProvince);
      const resultNTUCMember = await getNTUCMembers({ page: 1 });
      setMemberPagination(resultNTUCMember.data.pagination);
      setMembers(resultNTUCMember.data.results);
      if (id) {
        const resultMember = await getNTUCMember(id)
        if (resultMember.data) {
          setName(resultMember.data.results.name)
          setStructure(resultMember.data.results.structure)
          setRoll(resultMember.data.results.roll.toString())
          setProvince(resultMember.data.results.province)
          setAgency(resultMember.data.results.agency)
          setImagePreview(resultMember.data.results.media.mediaUrl)
          setValue('name', resultMember.data.results.name)
          setValue('province', resultMember.data.results.province)
          setValue('district', resultMember.data.results.district)
          setValue('roll', resultMember.data.results.roll.toString())
          setValue('agency', resultMember.data.results.agency)
          setValue('structure', resultMember.data.results.structure)
          const d = resultProvince.filter((ele: Province) => ele.province == parseInt(resultMember.data.results.province))
          setDistricts(d[0].district)
          setDistrict(resultMember.data.results.district)
          setAgency(resultMember.data.results.agency)
        }
      }

    })()
  }, [id]);

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this member?')) {
      const response = await deleteNTUCMember(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        setMemberPagination(response.data.pagination);
        setMembers(response.data.results);
      } else {
        toast.error(response.data.message);
      }
    }
  }
  const handleEdit = (id: string) => {
    navigate(`/add/${id}`);
  };
  return (
    <div className="p-10">
      <h1 className="text-2xl font-bold mb-4">Add Member</h1>

      <div className="grid grid-cols-2 gap-4">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          {/* Image */}
          <div>
            <label htmlFor="image" className="block font-medium text-gray-700">
              फोटो
            </label>
            <input
              id="image"
              type="file"
              accept="image/*"
              {...register("image")}
              className="mt-1  w-full text-gray-700 hidden"
              onChange={handleFileChange}
            />
            {errors.image && (
              <p className="text-red-500 text-sm mt-1">{errors.image.message}</p>
            )}
            {!imagePreview && (
              <div className="mt-4">
                <label htmlFor="image" onDragOver={handleDragOver}
                  onDrop={handleDrop} className="w-[200px] h-[200px] object-contain rounded-md shadow-md flex items-center justify-center bg-white cursor-pointer">
                  फोटो छान्नुहोस्
                </label>
              </div>
            )}
            {imagePreview && (
              <div className="mt-4 relative w-[200px]">
                <button type="button" className="absolute top-2 right-2 p-2 bg-red-500 rounded-md" onClick={() => setImagePreview(null)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="white"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-[200px] h-[200px] object-contain rounded-md shadow-md bg-white"
                />
              </div>
            )}
          </div>
          {/* Roll */}
          <div>
            <label htmlFor="roll" className="block font-medium text-gray-700">
              क्रम संख्या
            </label>
            <input
              id="roll"
              type="text"
              value={roll}
              {...register("roll", { required: "Roll is required"})}
              onChange={handleNepaliChange(setRoll)}
              className="mt-1 h-10 p-2 block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
            {errors.roll && (
              <p className="text-red-500 text-sm mt-1">{errors.roll.message}</p>
            )}
          </div>
          {/* Roll */}
          <div>
            <label htmlFor="structure" className="block font-medium text-gray-700">
              संरचना
            </label>
            <input
              id="structure"
              type="text"
      
              {...register("structure", { required: "Struture is required" })}
              value={structure}
              onChange={handleNepaliChange(setStructure)}
              className="mt-1 h-10 p-2 block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
            {errors.structure && (
              <p className="text-red-500 text-sm mt-1">{errors.structure.message}</p>
            )}
          </div>

          {/* Name */}
          <div>
            <label htmlFor="name" className="block font-medium text-gray-700">
              नाम
            </label>
            <input
              id="name"
              type="text"
              {...register("name", { required: "Name is required" })}
              value={name}
              onChange={handleNepaliChange(setName)}
              className="mt-1 h-10 p-2 block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
            )}
          </div>

          {/* Province Selector */}
          <div>
            <label htmlFor="province" className="block font-medium text-gray-700">
              प्रदेश
            </label>
            <select
              id="province"
              {...register("province", { required: "Province is required" })}
              value={province}
              onChange={(e) => { setProvince(e.target.value); setDistricts(provinces?.find((prov) => prov.province === parseInt(e.target.value))?.district || []); }}
              className="mt-1 h-10 p-2 block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="" disabled>
                प्रदेश छान्नुहोस्
              </option>
              {provinces && provinces.map((prov) => (
                <option key={prov.province} value={prov.province}>
                  {prov.name}
                </option>
              ))}
            </select>
            {errors.province && (
              <p className="text-red-500 text-sm mt-1">
                {errors.province.message}
              </p>
            )}
          </div>
          {/* District Selector */}
          <div>
            <label htmlFor="province" className="block font-medium text-gray-700">
              जिल्ला
            </label>
            <select
              id="district"
              {...register("district", { required: "District is required" })}
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              className="mt-1 h-10 p-2 block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="" disabled>
                जिल्ला छान्नुहोस्
              </option>
              {districts && districts.map((prov) => (
                <option key={prov.name} value={prov.name}>
                  {prov.name}
                </option>
              ))}
            </select>
            {errors.district && (
              <p className="text-red-500 text-sm mt-1">
                {errors.district.message}
              </p>
            )}
          </div>



          {/* Agency */}
          <div>
            <label htmlFor="agency" className="block font-medium text-gray-700">
              आवद्ध संघ
            </label>
            <select
              id="agency"
              {...register("agency", { required: "Agency is required" })}
              value={agency}
              onChange={(e) => setAgency(e.target.value)}
              className="mt-1 h-10 p-2 block w-full border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="" disabled>
                सम्बद्ध संघ छान्नुहोस्
              </option>
              {unions && unions.map((prov) => (
                <option key={prov.nepali} value={prov.nepali}>
                  {prov.nepali}
                </option>
              ))}
            </select>

            {errors.agency && (
              <p className="text-red-500 text-sm mt-1">{errors.agency.message}</p>
            )}
          </div>

          {/* Submit Button */}
          {!submitLoading && <button
            type="submit"
            className="w-[200px] bg-blue-500 text-white font-medium py-2 px-4 hover:bg-blue-600"
          >
            Save
          </button>}
          {submitLoading && <button
            type="button"
            disabled
            className="w-[200px] bg-blue-500 text-white font-medium py-2 px-4 hover:bg-blue-600"
          >
            Saving...
          </button>}
        </form>
        <div>
          <div>
            <ul>
              <li className="bg-white px-3 py-2 cursor-pointer mb-2 text-right"><strong>Total Members:</strong> {memberPagination && memberPagination.total}</li>
            </ul>
          </div>
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b text-left">फोटो</th>
                <th className="px-4 py-2 border-b text-left">क्रम संख्या</th>
                <th className="px-4 py-2 border-b text-left">नाम</th>
                <th className="px-4 py-2 border-b text-left">प्रदेश</th>
                <th className="px-4 py-2 border-b text-left">जिल्ला</th>
                <th className="px-4 py-2 border-b text-left">आवद्ध संघ</th>
                <th className="px-4 py-2 border-b text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {members && members.map((ele, index) => (
                <tr key={ele._id}>
                  <td className="px-4 py-2 border-b"><img src={ele.media.mediaUrl} alt="" className="w-[50px] h-[50px]" /></td>
                  <td className="px-4 py-2 border-b">{ele.roll}</td>
                  <td className="px-4 py-2 border-b">{ele.name}</td>
                  <td className="px-4 py-2 border-b">{ele.province}</td>
                  <td className="px-4 py-2 border-b">{ele.district}</td>
                  <td className="px-4 py-2 border-b">{ele.agency}</td>
                  <td className="px-4 py-2 border-b">
                    <IconButton onClick={() => handleEdit(ele._id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(ele._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </td>

                </tr>
              ))}
              {memberPagination && memberPagination.total === 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className="px-4 py-2 border-b text-center text-gray-500"
                  >
                    No members found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddPrintCard;
