import axios from "axios";
import { baseUrl } from "..";

export type NTUCAddressResult = Province[];

export interface Province {
  province: number;
  name: string;
  district: District[];
}

export interface District {
  name: string;
  list: List;
}

export interface List {
  code: string;
  repno: Repno[];
}

export interface Repno {
  code: number;
  napa: Napa[];
}

export interface Napa {
  code: string;
  name: string;
  ward: string[];
}

export const getNepalAddress: () => Promise<NTUCAddressResult> = async () => {
  try {
    const response = await axios.get(baseUrl + "ntuc/address");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export interface GobalResponseWithStatus {
  status: number;
  data: GobalResponse;
}

export interface GobalResponseWithSingleStatus {
  status: number;
  data: GobalResponseSingle;
}

//ntuc members
export interface GobalResponse {
  message: string;
  results: NTUCMemberList[];
  pagination: Pagination;
}
export interface GobalResponseSingle {
  message: string;
  results: NTUCMemberList;
  pagination: Pagination;
}

export interface NTUCMemberList {
  _id: string;
  roll: number;
  name: string;
  province: string;
  district: string;
  agency: string;
  media: GloablMedia;
  createdAt: string;
  updatedAt: string;
  structure:string;
  __v: number;
}

export interface GloablMedia {
  mediaUrl: string;
  fileType: string;
}

export interface Pagination {
  total: number;
  next?: number;
  previous?: number;
}

export const addNTUCMember: (
  data: FormData
) => Promise<GobalResponseWithStatus> = async (data: FormData) => {
  try {
    const response = await axios.post(baseUrl + "ntuc/createMember", data, {
      headers: {
        "Content-Type": "form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzRlOGZlY2MyNzQ1YjM1MzJmYjQ4OTciLCJ1c2VySGFuZGxlIjoiQF9iaW5hMTczMzIwMTkwMjQwMiIsImlhdCI6MTczMzUxNDYwOSwiZXhwIjoxNzM2MTA2NjA5fQ.sMywarHo_uI09egiDUFr6xktrLPe4B4f-pDyRy5EgV4`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// ntuc members list
export const getNTUCMembers: (
  data: any
) => Promise<GobalResponseWithStatus> = async (data: any) => {
  try {
    const response = await axios.post(baseUrl + "ntuc/listmembers", data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzRlOGZlY2MyNzQ1YjM1MzJmYjQ4OTciLCJ1c2VySGFuZGxlIjoiQF9iaW5hMTczMzIwMTkwMjQwMiIsImlhdCI6MTczMzUxNDYwOSwiZXhwIjoxNzM2MTA2NjA5fQ.sMywarHo_uI09egiDUFr6xktrLPe4B4f-pDyRy5EgV4`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// ntuc members list
export const getNTUCMember: (
  data: string
) => Promise<GobalResponseWithSingleStatus> = async (data: any) => {
  try {
    const response = await axios.get(baseUrl + "ntuc/member/" + data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzRlOGZlY2MyNzQ1YjM1MzJmYjQ4OTciLCJ1c2VySGFuZGxlIjoiQF9iaW5hMTczMzIwMTkwMjQwMiIsImlhdCI6MTczMzUxNDYwOSwiZXhwIjoxNzM2MTA2NjA5fQ.sMywarHo_uI09egiDUFr6xktrLPe4B4f-pDyRy5EgV4`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// delete member
export const deleteNTUCMember: (
  id: string
) => Promise<GobalResponseWithStatus> = async (id: string) => {
  try {
    const response = await axios.delete(baseUrl + "ntuc/deleteMember/" + id, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzRlOGZlY2MyNzQ1YjM1MzJmYjQ4OTciLCJ1c2VySGFuZGxlIjoiQF9iaW5hMTczMzIwMTkwMjQwMiIsImlhdCI6MTczMzUxNDYwOSwiZXhwIjoxNzM2MTA2NjA5fQ.sMywarHo_uI09egiDUFr6xktrLPe4B4f-pDyRy5EgV4`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};
